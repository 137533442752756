<template>
  <div>
    <!-- filter -->
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header class="py-1">
        <h4 class="card-title font-weight-bolder">
          {{ $t('filters') }}
        </h4>
        <div class="d-flex align-items-center justify-content-end">
          <!-- ANCHOR Button Search -->
          <b-button
            variant="info"
            class="mr-1"
            @click="fetch()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="SearchIcon"
                size="16"
              />
              {{ $t('search') }}
            </span>
          </b-button>

          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="danger"
            @click="fetch(true)"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="XOctagonIcon"
                size="16"
              />
              {{ $t('clear') }}
            </span>
          </b-button>
        </div>
      </b-card-header>

      <b-card-body class="py-0">
        <b-container fluid>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.accountHolderName')"
              >
                <b-form-input
                  v-model.trim="filter.q"
                  :placeholder="$t('sepay.search.bankAccount')"
                  debounce="500"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.fullName')"
              >
                <v-select
                  v-model="filter.companyId"
                  :placeholder="$t('sepay.columns.fullName')"
                  :options="companies"
                  :clearable="false"
                  :reduce="e => e.id"
                  label="fullName"
                  @search="searchCompany"
                >
                  <template #option="data">
                    <span>
                      {{ data.id }} - {{ data.fullName }} ({{ data.shortName }})
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ data.id }} - {{ data.fullName }} ({{ data.shortName }})
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <!-- table -->
    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 70vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="items"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.isSortDirDesc"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              {{ $t(`sepay.columns.${data.label}`) }}
            </div>
          </template>

          <!-- ANCHOR Value -->
          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR lastTransaction. -->
          <template #cell(lastTransaction)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.lastTransaction, 0).dateTime }}
            </span>
          </template>
          <!-- ANCHOR accumulated. -->
          <template #cell(accumulated)="{ item }">
            <span class="text-right">
              {{ formatVnCurrency(item.accumulated) }}
            </span>
          </template>

          <!-- ANCHOR paymentTxsFee. -->
          <template #cell(paymentTxsFee)="{ item }">
            {{ item.paymentTxsFee? formatVnCurrency(item.paymentTxsFee ): 'Chưa cài đặt' }}
          </template>
          <!-- ANCHOR bankApiConnected. -->
          <template #cell(bankApiConnected)="{ item }">
            <span class="text-right">
              {{ $t(item.bankApiConnected == 1 ? 'sepay.connected' : 'sepay.disconnected') }}
            </span>
          </template>

          <!-- ANCHOR actions. -->
          <template #cell(actions)="{ item }">
            <!-- ANCHOR btnUpdate. -->
            <b-button
              v-if="item.paymentBankAccountId"
              id="btnUpdate"
              v-b-tooltip.hover.v-dark.window="$t('update')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="py-50 px-75 rounded"
              size="md"
              @click="onUpdate(item)"
            >
              <IAmIcon
                icon="editOutline"
                size="18"
              />
            </b-button>
          </template>
          <!-- ANCHOR bankId. -->
          <template #cell(bankId)="{ item }">
            <span class="d-flex gap-1 align-items-center">
              <BImg
                :src="resoleBank(item.bankId).logo_path"
                width="30"
              />
              <div>{{ resoleBank(item.bankId).short_name }} </div>
            </span>
          </template>
        </b-table>
      </b-overlay>
      <!-- paging -->
      <IAmPaging
        :page="filter.page"
        :size="filter.perPage"
        :total="total"
        :refDataTable="refDataTable"
        @set-page="(val)=>filter.page = val"
        @set-size="(val)=>filter.perPage = val"
      />
    </b-card>

    <!-- embed -->
    <b-card>
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="bank-account-form-modal"
          :title="$t('bankAccount.title')"
          size="lg"
          :centered="true"
          :scrollable="false"
          :hide-header="false"
          :hide-footer="false"
          :no-close-on-backdrop="false"
          :no-close-on-esc="false"
          :ok-only="false"
          :ok-disabled="false"
          ok-title="OK"
          ok-variant="primary"
          cancel-title="Cancel"
          cancel-variant="secondary"
          @show="onShow"
        >
          <IAmOverlay :loading="loadingForm">
            <!-- ANCHOR paymentTxsFee -->
            <b-col>
              <validation-provider
                #default="validationContext"
                :name="$t('bank.form.paymentTxsFee')"
                vid="paymentTxsFee"
                rules="required"
              >
                <b-form-group label-for="paymentTxsFee">
                  <template #label>
                    {{ $t('bank.form.paymentTxsFee') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="paymentTxsFee"
                    v-model="bankAccountUpdate.paymentTxsFee"
                    v-remove-non-numeric-chars.allNumber
                    :name="$t('bank.form.paymentTxsFee')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('bank.form.paymentTxsFee')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </IAmOverlay>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right"
                :disabled="invalid"
                @click="onSubmitUpdate"
              >
                {{ $t('submit') }}
              </b-button>
            </div>
          </template>
        </b-modal>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BContainer,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { cloneDeep, debounce } from 'lodash-es'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { getDifference } from '@/@core/utils/utils'
import { apiBankAccount } from '@/api'
import IAmOverlay from '@/components/IAmOverlay.vue'
import IAmPaging from '@/components/IAmPaging.vue'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import useToast from '@/libs/toastification/useToast'

import {
  convertISODateTime,
  formatVnCurrency,
} from '@core/utils/filter'

import useSepayHandle from '@sepay/useSepayHandle'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BContainer,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BImg,
    vSelect,
    IAmPaging,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    IAmOverlay,
  },
  setup() {
    //
    const {
      loading,
      bankAccounts: items,
      filterBankAccount: filter,
      totalBankAccount: total,
      fetchBankAccounts: fetch,
      companies,
      filterCompany,
      fetchCompanies,
    } = useSepayHandle()
    const { toastSuccess, toastError } = useToast()

    onMounted(() => {
      fetch()
      fetchCompanies()
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()
    const refDataTable = ref(null)
    const tableColumns = ref([
      { key: 'stt', label: 'stt' },
      { key: 'bankId', label: 'bankId' },
      { key: 'accountHolderName', label: 'accountHolderName' },
      { key: 'accountNumber', label: 'accountNumber' },
      { key: 'accumulated', label: 'accumulated' },
      { key: 'paymentTxsFee', label: 'paymentTxsFee' },
      { key: 'label', label: 'label' },
      { key: 'bankApiConnected', label: 'bankApiConnected' },
      { key: 'lastTransaction', label: 'lastTransaction' },
    ])

    const searchCompany = debounce(search => {
      filterCompany.value.q = search
      fetchCompanies()
    }, 300)

    function resoleBank(bankId) {
      const banks = [
        {
          id: '3',
          brand_name: 'ACB',
          full_name: 'Ngân hàng TMCP Á Châu',
          short_name: 'ACB',
          code: 'ACB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/acb.png',
        },
        {
          id: '8',
          brand_name: 'MBBank',
          full_name: 'Ngân hàng TMCP Quân Đội',
          short_name: 'MBBank',
          code: 'MB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/mbbank.png',
        },
        {
          id: '18',
          brand_name: 'OCB',
          full_name: 'Ngân hàng TMCP Phương Đông',
          short_name: 'OCB',
          code: 'OCB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/ocb.png',
        },
      ]
      const bank = banks.find(e => e.id === bankId)
      return bank
    }
    const bankAccountId = ref(null)
    const bankAccount = ref(null)
    const bankAccountUpdate = ref({ paymentTxsFee: 0 })
    const loadingForm = ref(true)
    async function onShow() {
      if (bankAccountId.value) {
        // get detail bank account
        const { data } = await apiBankAccount.getBankAccount(bankAccountId.value)
        data.paymentTxsFee = data.paymentTxsFee || 0
        bankAccount.value = cloneDeep(data)
        bankAccountUpdate.value = cloneDeep(data)
        loadingForm.value = false
      } else {
        this.$bvModal.hide('bank-account-form-modal')
      }
    }

    function onUpdate(item) {
      bankAccountId.value = item.paymentBankAccountId
      this.$bvModal.show('bank-account-form-modal')
    }
    async function onSubmitUpdate() {
      try {
        loadingForm.value = true
        const payload = getDifference(bankAccountUpdate.value, bankAccount.value)
        if (Object.keys(payload).length > 0 && payload.constructor === Object) {
          await apiBankAccount.updateBankAccount(bankAccountId.value, payload)
          this.$bvModal.hide('modal-edit-bank-accounts')
          toastSuccess('bank.updateSuccess')
        }
      } catch (error) {
        toastError(error)
      } finally {
        loadingForm.value = false
        fetch()
        this.$bvModal.hide('bank-account-form-modal')
      }
    }
    return {
      onShow,
      onUpdate,
      onSubmitUpdate,
      bankAccountUpdate,
      loading,
      loadingForm,
      filter,
      items,
      total,
      tableColumns,
      refDataTable,
      fetch,
      companies,
      searchCompany,
      resoleBank,
      // import
      sizePerPageLgOptions,
      convertISODateTime,
      formatVnCurrency,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
